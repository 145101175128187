.pagination>li {
    display: inline-block;
    padding-left: 0;
  
  }
  
  .pagination>li {
    list-style: none;
  }
  
  .pagination>li>a,
  .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #0e4875 !important;
  
  
  }
  
  .pagination>li.active>a {
    color: rgb(255, 255, 255) !important;
    background-color: #0e4875;
    border-color: #fb445a3b !important;
  }

  .blink {
    animation: blinker 3s linear infinite;
    color: #ffc800;
    font-weight: 700;
    margin-right: 20px;
  }
  
  .blink2 {
    animation: blinker 3s linear infinite;
    color: #ff508a;
    font-weight: 700;
    margin-right: 20px;
  }

    
  .blink3 {
    animation: blinker 3s linear infinite;
    color: #87fa78;
    font-weight: 700;
    margin-right: 20px;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0.5;
    }
  }
  